<template lang="pug">
.error-page

  .content

    h1 {{ error.statusCode }}
</template>
<script setup lang="ts">
const { $sentry } = useNuxtApp()

const props = defineProps({
  error: {
    type: Object,
    required: true,
  },
})

console.error(props.error)

onMounted(() => {
  $sentry.captureException(props.error)
})
</script>
<style lang="sass" scoped>
.error-page
  width: 100%

  .content
    max-width: 600px
    margin: 0 auto
    text-align: center

    h1
      font-weight: 400

    a
      color: #42b983
      text-decoration: none

      &:hover
        text-decoration: underline
</style>